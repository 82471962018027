import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { defaultTo } from 'ramda'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import EmployeeByRoleSelect from '@Auth/Components/EmployeeByRoleSelect'
import Alert from '@Common/Components/Alert'
import DatePickerField from '@Common/Components/Forms/DatePickerField'
import EditorField from '@Common/Components/Forms/EditorField'
import TextField from '@Common/Components/Forms/TextField'
import { Box, Margin } from '@Common/Components/Styles'
import { makePath } from '@Config'
import LabeledBox from '@Tms/Components/LabeledBox'
import { StatusBadge } from '@Tms/Components/Styled'
import PrioritySelect from '@Tms/Forms/Selects/PrioritySelect'
import TaskCustomField from '@Tms/Forms/TaskCustomField'
import { RunningTaskType } from '@Tms/Models/RunningTask'
import { getTaskStatus, STATUS_ENUM, TASK_FIELD_TYPE_IDS } from '@Tms/Utils'

const CUSTOM_FIELDS_GRID_MAP = {
  [TASK_FIELD_TYPE_IDS.text]: 6,
  [TASK_FIELD_TYPE_IDS.textarea]: 12,
  [TASK_FIELD_TYPE_IDS.select]: 12,
  [TASK_FIELD_TYPE_IDS.date]: 6,
  [TASK_FIELD_TYPE_IDS.attachment]: 12,
  [TASK_FIELD_TYPE_IDS.resident]: 6,
  [TASK_FIELD_TYPE_IDS.employee]: 6,
  [TASK_FIELD_TYPE_IDS.supplier]: 6,
  [TASK_FIELD_TYPE_IDS.branchAdmin]: 6,
  [TASK_FIELD_TYPE_IDS.url]: 6,
  [TASK_FIELD_TYPE_IDS.utilities]: 12,
  [TASK_FIELD_TYPE_IDS.building]: 6,
}

const UpdateTaskForm = ({ task, isTaskReadOnly }) => {
  const { t } = useTranslation()
  const status = getTaskStatus(task)
  const {
    control,
    formState: { errors },
    reset,
  } = useFormContext()

  useEffect(() => {
    const fields = buildTaskFields(task)
    reset(fields, { keepTouched: false, keepDirty: false })
  }, [task])

  const buildTaskFields = (task) => {
    const { title, priority, notes, assignedTo, deadline } = task
    return {
      title,
      priority,
      notes,
      assignedTo,
      deadline: dayjs(deadline),
    }
  }

  return (
    <>
      <Box direction="row" align="center" margin="0 0 1rem">
        <Typography fontWeight={600} mr={2}>
          {t('tms:ui.TaskData')}
        </Typography>
        <StatusBadge
          status={status}
          size="small"
          label={t(
            `tms:ui.Status${
              status === STATUS_ENUM.COMPLETED
                ? 'Completed'
                : status === STATUS_ENUM.EXPIRED
                ? 'Expired'
                : status === STATUS_ENUM.PROCESSING
                ? 'Processing'
                : 'Open'
            }`,
          )}
        />
      </Box>
      <Box>
        {errors.__form?.message && (
          <Alert level="warning" sx={{ width: '100%', boxSizing: 'border-box', mb: '1rem' }}>
            {errors.__form.message}
          </Alert>
        )}
        <Grid container rowSpacing={3} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={isTaskReadOnly}
              fullWidth
              required
              name="title"
              control={control}
              label={t('tms:fields.title')}
              error={!!errors.title}
              helperText={errors.title?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabeledBox label={t('tms:fields.parentTask')}>
              {!task.parent && '-'}
              {!!task.parent && (
                <RouterLink to={makePath('tms.taskDetail', { taskId: task.parent?.id })}>
                  <Box direction="row" align="center">
                    <Margin right="5px">{task.parent?.title}</Margin>
                    <OpenInNewIcon fontSize="1rem" />
                  </Box>
                </RouterLink>
              )}
            </LabeledBox>
          </Grid>
          <Grid item xs={12} mb={2}>
            <LabeledBox label={t('tms:fields.description')}>
              <Box background="lightOne" width="100%" pad="1rem 0.5rem" radius="6px">
              <span dangerouslySetInnerHTML={{ __html: task.description }} />
              </Box>
            </LabeledBox>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="assignedTo"
              control={control}
              render={({ field: { onChange, value } }) => (
                <EmployeeByRoleSelect
                  searchable
                  disabled={isTaskReadOnly}
                  required
                  roles={[]}
                  label={t('tms:ui.AssignTo')}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PrioritySelect
              disabled={isTaskReadOnly}
              required
              name="priority"
              control={control}
              label={t('tms:fields.priority.__field')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePickerField
              fullWidth
              disabled={isTaskReadOnly}
              required={!!task?.deadline}
              name="deadline"
              control={control}
              label={t('tms:fields.deadline')}
            />
          </Grid>
          {task.taskTemplateObj.fields.map((ft) => (
            <Grid item xs={12} sm={CUSTOM_FIELDS_GRID_MAP[ft.type]} key={ft.id}>
              <TaskCustomField
                control={control}
                field={ft}
                disabled={isTaskReadOnly}
                isTaskUpdate={!!task?.id}
                value={defaultTo(
                  null,
                  task.fields.find((f) => f.taskFieldTemplate === ft.id),
                )}
              />
            </Grid>
          ))}
          <Grid item xs={12} mb={2}>
            {isTaskReadOnly && (
              <LabeledBox label={t('tms:fields.notes')}>
                <Box background="lightOne" width="100%" pad="1rem 0.5rem" radius="6px">
                  <span dangerouslySetInnerHTML={{ __html: task.notes }} />
                </Box>
              </LabeledBox>
            )}
            {!isTaskReadOnly && <EditorField name="notes" control={control} label={t('tms:fields.notes')} />}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

UpdateTaskForm.propTypes = {
  task: RunningTaskType,
  isTaskReadOnly: PropTypes.bool,
}

export default UpdateTaskForm
