import { Navigate, Route, Routes } from 'react-router-dom'

import { EmployeesPermissions, InvitationsPermissions } from '@Auth/Permissions'
import AdminAnagraphicsInvitationsView from '@Auth/Views/AdminAnagraphicsInvitationsView'
import AdminBuildingInvitationsView from '@Auth/Views/AdminBuildingInvitationsView'
import AdminEmployeesView from '@Auth/Views/AdminEmployeesView'
import AdminInvitationsView from '@Auth/Views/AdminInvitationsView'
import { canAccessBranchesView } from '@Branches/Permissions'
import BranchesView from '@Branches/Views/BranchesView'
import { canAccessBuildingsView } from '@Buildings/Permissions'
import { BuildingsRouter } from '@Buildings/Router/BuildingsRouter'
import PrivateRoute from '@Common/Components/PrivateRoute'
import { InsurancePolicyRouter } from '@Condo/Router/InsurancePolicyRouter'
import { PodPdrRouter } from '@Condo/Router/PodPdrRouter'
import { ReconciliationReminderRouter } from '@Condo/Router/ReconciliationReminderRouter'
import config from '@Config'
import { NewsPermissions } from '@News/Permissions'
import { NotificationsPermissions } from '@Notifications/Permissions'
import AdminNotificationsView from '@Notifications/Views/AdminNotificationsView'
import { ResidentsPermissions } from '@Residents/Permissions'
import AdminResidentsView from '@Residents/Views/AdminResidentsView'
import { CategoryPermissions, LabelPermissions, ProviderPermissions, ServicePermissions } from '@Services/Permissions'
import { AdminCategoryView } from '@Services/Views/CategoryAdminView'
import { AdminLabelView } from '@Services/Views/LabelAdminView'
import { AdminProviderView } from '@Services/Views/ProviderAdminView'
import { AdminServiceView } from '@Services/Views/ServiceAdminView'
import { SupplierRouter } from '@Supplier/Router/SupplierRouter'
import { ReportTypesPermissions, ReportsPermissions } from '@Tickets/Permissions'
import ReportDetailView from '@Tickets/Views/ReportDetailView'

import AdminNewsView from '../../News/Views/AdminNewsView'
import AdminReportTypesView from '../../Tickets/Views/AdminReportTypesView'
import AdminReportsView from '../../Tickets/Views/AdminReportsView'
import AdminDashboardView from '../Views/AdminDashboardView'

const AdminTicketsRoutes = () => (
  <Routes>
    <Route
      exact
      path={config.urls.admin.tickets.types}
      element={
        <PrivateRoute permFunction={ReportTypesPermissions.read}>
          <AdminReportTypesView />
        </PrivateRoute>
      }
    />
    <Route
      path={config.urls.admin.tickets.reports}
      element={
        <PrivateRoute permFunction={ReportsPermissions.read}>
          <AdminReportsView />
        </PrivateRoute>
      }
    />
    <Route
      path={config.urls.admin.tickets.report}
      element={
        <PrivateRoute permFunction={ReportsPermissions.read}>
          <ReportDetailView />
        </PrivateRoute>
      }
    />
  </Routes>
)

const AdminServicesRoutes = () => (
  <Routes>
    <Route
      exact
      path={config.urls.admin.services.labels}
      element={
        <PrivateRoute permFunction={LabelPermissions.create}>
          <AdminLabelView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.services.providers}
      element={
        <PrivateRoute permFunction={ProviderPermissions.create}>
          <AdminProviderView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.services.categories}
      element={
        <PrivateRoute permFunction={CategoryPermissions.create}>
          <AdminCategoryView />
        </PrivateRoute>
      }
    />
    <Route
      path={config.urls.admin.services.services}
      element={
        <PrivateRoute permFunction={ServicePermissions.create}>
          <AdminServiceView />
        </PrivateRoute>
      }
    />
  </Routes>
)

export const AdminRoutes = () => (
  <Routes>
    <Route
      exact
      path={config.urls.admin.dashboard}
      element={
        <PrivateRoute>
          <AdminDashboardView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.branches}
      element={
        <PrivateRoute permFunctiontion={canAccessBranchesView}>
          <BranchesView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.employees}
      element={
        <PrivateRoute permFunction={EmployeesPermissions.read}>
          <AdminEmployeesView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.residents}
      element={
        <PrivateRoute permFunction={ResidentsPermissions.read}>
          <AdminResidentsView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.invitations}
      element={
        <PrivateRoute permFunction={InvitationsPermissions.read}>
          <AdminInvitationsView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.buildingInvitations}
      element={
        <PrivateRoute permFunction={InvitationsPermissions.create}>
          <AdminBuildingInvitationsView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.anagraphicsInvitations}
      element={
        <PrivateRoute permFunction={InvitationsPermissions.create}>
          <AdminAnagraphicsInvitationsView />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={config.urls.admin.news}
      element={
        <PrivateRoute permFunction={NewsPermissions.view}>
          <AdminNewsView />
        </PrivateRoute>
      }
    />
    <Route path={config.urls.admin.services.base} element={<AdminServicesRoutes />} />
    <Route
      exact
      path={config.urls.admin.notifications}
      element={
        <PrivateRoute permFunction={NotificationsPermissions.create}>
          <AdminNotificationsView />
        </PrivateRoute>
      }
    />

    <Route path={config.urls.admin.tickets.base} element={<AdminTicketsRoutes />} />

    <Route element={<PrivateRoute permFunction={canAccessBuildingsView} />}>
      <Route path="buildings/*" element={<BuildingsRouter />} />
      <Route path="podpdrs/*" element={<PodPdrRouter />} />
      <Route path="insurance-policy/*" element={<InsurancePolicyRouter />} />
      <Route path="reconciliation-reminders/*" element={<ReconciliationReminderRouter />} />
      <Route path="supplier/*" element={<SupplierRouter />} />
    </Route>

    <Route path="*" element={<Navigate to={config.urls.admin.dashboard} />} />
  </Routes>
)
