import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectActiveBranch } from '@Branches/Redux'
import { useBranchesQuery } from '@Branches/Services/Api'
import NotesForm from '@Buildings/Forms/NotesForm'
import { useUpdateBranchBuildingNotesMutation } from '@Buildings/Services/Api'
import AddFab from '@Common/Components/AddFab'
import { responseStringError } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import BranchCondoList from '@Condo/Components/BranchCondoList'
import CondoForm from '@Condo/Forms/CondoForm/CondoForm'
import { CondosPermissions } from '@Condo/Permissions'
import {
  useAssignCondoOwnersMutation,
  useCreateCondoMutation,
  useDeleteCondoMutation,
  useUpdateCondoMutation,
} from '@Condo/Services/Api'
import { apiList } from '@Core/Services/Api'

import BranchBuildingsList from '../Components/BranchBuildingsList'
import BuildingOwnersForm from '../Forms/BuildingOwnersForm'
import { useAssignBranchBuildingOwnersMutation } from '../Services/Api'

export const BranchBuildingsView = () => {
  const { t } = useTranslation()
  const [updateBranchBuildingNotes] = useUpdateBranchBuildingNotesMutation()
  const [assignBranchBuildingOwners] = useAssignBranchBuildingOwnersMutation()
  const [assignCondoOwners] = useAssignCondoOwnersMutation()
  const [building, setBuilding] = useState(null)
  const [ownersForm, setOwnersForm] = useState({ open: false, buildings: [], buildingType: null })
  const { data: branches } = apiList(useBranchesQuery())
  const branch = useSelector(selectActiveBranch)
  const [form, setForm] = useState({ isOpen: false, condo: null, isEditing: false })
  const user = useCurrentUser()

  // api
  const [createCondo] = useCreateCondoMutation()
  const [updateCondo] = useUpdateCondoMutation()
  const [deleteCondo] = useDeleteCondoMutation()

  useBreadcrumbs([{ label: t('buildings:ui.Buildings'), Icon: 'Building' }], branch?.name, [branch])

  // handle tabs change
  const [tab, setTab] = useState('buildings')
  const handleTabChange = (event, newValue) => setTab(newValue)

  // handle owners form
  const handleOpenOwnersForm = (buildingType) => (buildings) => setOwnersForm({ open: true, buildings, buildingType })
  const handleCloseOwnersForm = () => setOwnersForm({ open: false, buildings: [], buildingType: null })

  // handle save owners
  const handleSaveOwners = useCallback(
    async (fields) => {
      try {
        const saveFn = ownersForm.buildingType === 'condo' ? assignCondoOwners : assignBranchBuildingOwners
        const response = await saveFn(fields).unwrap()
        toast.success(t('buildings:success.BranchBuildingOwnersAssigned'))
        return { response, isSuccessful: true }
      } catch (error) {
        toast.error(t('buildings:errors.AssignBranchBuildingOwnersError', { error: responseStringError(error) }))
        return { error, isSuccessful: false }
      }
    },
    [ownersForm.buildingType],
  )

  // handle note modal
  const handleOpenForm = (building) => setBuilding(building)
  const handleCloseForm = () => {
    setBuilding(null)
    setForm({ isOpen: false, condo: null, isEditing: false })
  }

  // handle save note
  const handleUpdateNotes = async (fields) => {
    try {
      const response = await updateBranchBuildingNotes(fields).unwrap()
      toast.success(t('buildings:success.BranchBuildingNotesUpdated', { name: building.denominazione }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('buildings:errors.UpdateBranchBuildingNotesError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleOpenCreateForm = () => setForm({ isOpen: true, condo: {}, isEditing: false })
  const handleOpenEditForm = (condo) => setForm({ isOpen: true, condo, isEditing: true })

  const handleCreateCondo = async (fields) => {
    try {
      const response = await createCondo(fields).unwrap()
      toast.success(
        t('condo:success.CondoCreated', {
          name: form.condo.description,
        }),
      )
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.CreateCondoError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleUpdateCondo = async (fields) => {
    try {
      const response = await updateCondo(fields).unwrap()
      toast.success(t('condo:success.CondoUpdated', { name: fields.description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.UpdateCondoError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const handleSave = (fields) => {
    return form.isEditing ? handleUpdateCondo(fields) : handleCreateCondo(fields)
  }

  const handleDelete = async (fields) => {
    try {
      const response = await deleteCondo(fields[0].id).unwrap()
      toast.success(t('condo:success.CondoDeleted', { name: fields[0].description }))
      return { response, isSuccessful: true }
    } catch (error) {
      toast.error(t('condo:errors.DeleteCondoError', { error: responseStringError(error) }))
      return { error, isSuccessful: false }
    }
  }

  const renderArcadiaTab = (
    <TabPanel value="buildings" sx={{ px: 0 }}>
      <BranchBuildingsList
        branches={branch?.id ? [branch] : branches}
        onEditNotes={handleOpenForm}
        onAssignOwners={handleOpenOwnersForm('branchbuilding')}
        source="buildings"
      />
    </TabPanel>
  )

  const renderLocalTab = (
    <TabPanel value="condo" sx={{ px: 0 }}>
      <BranchCondoList
        onDelete={handleDelete}
        onEdit={handleOpenEditForm}
        branches={branch?.id ? [branch] : branches}
        onAssignOwners={handleOpenOwnersForm('condo')}
      />
      {CondosPermissions.create(user) && <AddFab onClick={handleOpenCreateForm} />}
    </TabPanel>
  )

  return (
    <>
      <TabContext value={tab}>
        <TabList onChange={handleTabChange}>
          <Tab label="Arcadia" value="buildings" />
          <Tab label="Locali" value="condo" />
        </TabList>

        {renderArcadiaTab || null}
        {renderLocalTab || null}
      </TabContext>

      {ownersForm.open && (
        <BuildingOwnersForm
          buildings={ownersForm.buildings}
          buildingType={ownersForm.buildingType}
          onSave={handleSaveOwners}
          onClose={handleCloseOwnersForm}
        />
      )}
      {form.isOpen && <CondoForm condo={form.condo} onSave={handleSave} onClose={handleCloseForm} />}
      {building && <NotesForm building={building} onSave={handleUpdateNotes} onClose={handleCloseForm} />}
    </>
  )
}
