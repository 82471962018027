import { api } from '@Core/Services/Api'

const prefix = 'company/branches'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    branches: builder.query({
      query: () => `${prefix}`,
      providesTags: [{ type: 'Branch', id: 'LIST' }],
    }),
    branch: builder.query({
      query: (branchId) => `${prefix}/${branchId}`,
      providesTags: (_, __, branchId) => [{ type: 'Branch', id: branchId }],
    }),
    deleteBranch: builder.mutation({
      query: (branchId) => ({
        url: `${prefix}/${branchId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, branchId) => [{ type: 'Branch', id: branchId }, { type: 'Branch', id: 'LIST' }, 'Employee'],
    }),
    createBranch: builder.mutation({
      query: (branch) => ({
        url: `${prefix}`,
        body: branch,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Branch', id: 'LIST' }, 'Employee'],
    }),
    updateBranch: builder.mutation({
      query: ({ id, ...branch }) => ({
        url: `${prefix}/${id}`,
        body: branch,
        method: 'PUT',
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Branch', id: id }, { type: 'Branch', id: 'LIST' }, 'Employee'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useBranchesQuery,
  useBranchQuery,
  useDeleteBranchMutation,
  useCreateBranchMutation,
  useUpdateBranchMutation,
} = extendedApi
