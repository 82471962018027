import dayjs from 'dayjs'
import { defaultTo, isNil } from 'ramda'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { convertServerErrors } from '@Common/Utils/Form'
import logger from '@Common/Utils/Logger'

const useUpdateTaskForm = (fields, onSave, onCancel, setIsSubmitting = null) => {
  const { control, setError, ...methods } = useForm()

  const setErrors = useCallback((errors) => {
    Object.entries(errors).map(([k, v]) => setError(k, { type: 'custom', message: v }))
  }, [])

  const onInvalid = useCallback((errors) => {
    logger.debug('Task form submission, validation failed, errors:', errors)
  }, [])

  const onSubmit = (isCompleted = false) =>
    useCallback(async (fields) => {
      logger.debug('Task form submission, validation passed, fields:', fields)

      const formData = new FormData()
      formData.append('isCompleted', isCompleted ? 'true' : 'false')
      for (let key in fields) {
        switch (key) {
          case 'deadline':
            formData.append(`deadline`, fields[key].isValid() ? fields[key].format('YYYY-MM-DDThh:mm') : '')
            break
          case 'customFields':
            for (let customFieldKey in fields[key]) {
              formData.append(`fields[${customFieldKey}]taskFieldTemplate`, customFieldKey)
              for (let customValueKey in fields[key][customFieldKey]) {
                let customValue = fields[key][customFieldKey][customValueKey]
                let fieldKey = `fields[${customFieldKey}]${customValueKey}`
                switch (customValueKey) {
                  case 'dateValue':
                    customValue = dayjs(customValue).isValid() ? dayjs(customValue).format('YYYY-MM-DD') : ''
                    formData.append(fieldKey, customValue)
                    break
                  case 'file':
                    formData.append(fieldKey, defaultTo('', customValue[0]))
                    break
                  case 'utilities':
                    for (let utilityValueKey in customValue) {
                      let utilityValue = customValue[utilityValueKey]
                      if (utilityValueKey.endsWith('LastBillDate')) {
                        utilityValue = dayjs(utilityValue).isValid() ? dayjs(utilityValue).format('YYYY-MM-DD') : ''
                      }
                      if (!isNil(utilityValue)) {
                        formData.append(`${fieldKey}.${utilityValueKey}`, defaultTo('', utilityValue))
                      } else {
                        formData.delete(`mainRunningTask.fields[${customFieldKey}]taskFieldTemplate`)
                      }
                    }
                    break
                  case 'building':
                    if (customValue) {
                      formData.append(`${fieldKey}_id`, defaultTo('', customValue.split('_')[0]))
                      formData.append(`${fieldKey}_type`, defaultTo('', customValue.split('_')[1]))
                    }
                    break
                  default:
                    formData.append(fieldKey, defaultTo('', customValue))
                }
              }
            }
            break
          default:
            formData.append(key, defaultTo('', fields[key]))
        }
      }

      if (setIsSubmitting) setIsSubmitting(true)
      const { error, isSuccessful } = await onSave(formData)
      if (!isSuccessful && error?.status === 400) {
        // validation error
        logger.debug('Task form submission api error', error)
        setErrors(convertServerErrors(error))
      } else if (isCompleted) {
        // task completed
        onCancel()
      }

      if (setIsSubmitting) setIsSubmitting(false)
    }, [])

  return {
    ...methods,
    control,
    errors: methods.formState.errors,
    onSubmit: (isCompleted) => methods.handleSubmit(onSubmit(isCompleted), onInvalid),
  }
}

export default useUpdateTaskForm
