import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { FieldType } from '@Common/Types/ReactHookFormField'

import StandardTextField from '../TextField'

export const TextField = ({ name, control, label, variant, rules, defaultValue, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <StandardTextField onChange={onChange} value={value} label={label} variant={variant} {...other} />
      )}
    />
  )
}

TextField.defaultProps = {
  variant: 'standard',
}

TextField.propTypes = FieldType & { variant: PropTypes.string, rules: PropTypes.object }

export default TextField
