import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import i18next from 'i18next'
import * as R from 'ramda'
import { toast } from 'react-toastify'

import { store } from '@Core/Redux/Store'

import { logout, setCredentials } from '../../Auth/Redux/Login'
import storageFactory from '../../Common/Services/Storage'
import logger from '../../Common/Utils/Logger'
import config from '../../Config'

const Storage = storageFactory()

let refreshTokenPromise
let refreshLocked = false // lock request retry while refreshing

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiBasePath,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().auth.login.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  const refreshToken = Storage.get('refreshToken', false)
  if (result.error && result.error.status === 401 && refreshToken) {
    logger.debug('API 401 detected', args)
    if (!refreshLocked) {
      logger.debug('API no one is refreshing -> acquiring lock and actually refreshing now', args)
      refreshLocked = true // acquire lock
      let resolveRefreshTokenPromise
      refreshTokenPromise = new Promise((resolve) => {
        resolveRefreshTokenPromise = resolve
      })
      const credentials = await baseQuery(
        {
          url: 'auth/token/refresh',
          method: 'POST',
          body: { refresh: refreshToken },
        },
        api,
        extraOptions,
      )

      if (credentials.data) {
        logger.debug('API token refreshed', args)
        api.dispatch(setCredentials(credentials.data))
        logger.debug('API credentials set', args)
        logger.debug('API releasing refresh lock', args)
        resolveRefreshTokenPromise()
        refreshLocked = false
      } else {
        resolveRefreshTokenPromise()
        refreshLocked = false
        api.dispatch(logout())
        location.assign(config.urls.login)
        return result
      }
    }

    logger.debug('API would like to run again request', args)
    if (refreshLocked) {
      logger.debug('API wait, someone is refreshing token', args)
      await refreshTokenPromise
      logger.debug('API wait for other refreshing: DONE', args)
    }

    logger.debug('API actually running again request', args)
    // retry the initial query
    result = await baseQuery(args, api, extraOptions)
    logger.debug('API request retry: DONE', args)
  }

  return result
}

// https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'GenericItem',
    'Anagraphic',
    'AuthenticatedUser',
    'Branch',
    'Building',
    'BranchBuilding',
    'Condo',
    'PodPdr',
    'CustomerCareAnagraphic',
    'CustomerCareBuilding',
    'Employee',
    'Invitation',
    'News',
    'Notification',
    'NotificationTopic',
    'Labels',
    'Services',
    'Providers',
    'ExternalSales',
    'Categories',
    'ResidentInstallment',
    'Role',
    'Resident',
    'ResidentNote',
    'Email',
    'User',
    'PersonalDocuments',
    'MeetingDocuments',
    'GeneralDocuments',
    'AccountingDocuments',
    'SecurityDocuments',
    'WorksDocuments',
    'ContractsDocuments',
    'InvoicesDocuments',
    'SupplierQuotesDocuments',
    'BuildingFinancialStatementsDocuments',
    'BuildingBankStatementsDocuments',
    'ResidentsBankStatementsDocuments',
    'ResidentsReceiptsDocuments',
    'EmployeesDocuments',
    'ShipmentSlipsDocuments',
    'ResidentsPersonalDocuments',
    'Report',
    'ReportType',
    'ReportMessage',
    'StripeAccount',
    'StripeAccountExternalAccounts',
    'PaySessionStripe',
    'AvailablePaymentMethods',
    'InsurancePolicy',
    'ReconciliationReminder',
    'InsurancePolicyPaymentsLogs',
    'TmsTaskCategory',
    'TmsTaskFlow',
    'TmsTask',
  ],
  endpoints: (build) => ({
    // list
    getItems: build.query({
      query: ({ url, filters }) => ({
        url,
        method: 'GET',
        params: { limit: 10, ...filters?.base, ...filters?.qsAdditions },
      }),
      providesTags: ['GenericItem'],
    }),

    // detail
    getItem: build.query({
      query: (url) => url,
      providesTags: ['GenericItem'],
    }),

    // post custom call, example resend notification, etc
    generic: build.mutation({
      query: ({ url, item, method = 'POST' }) => ({
        url: url,
        method: method,
        body: item ? item : null,
      }),
      invalidatesTags: ['GenericItem'],
    }),

    // update or create
    updateOrCreateItem: build.mutation({
      query: ({ url, item }) => ({
        url: item.id ? `${url}/${item.id}` : url,
        method: item.id ? 'PUT' : 'POST',
        body: item,
      }),
      invalidatesTags: ['GenericItem'],
    }),

    // delete
    deleteItem: build.mutation({
      query: (url) => ({
        url: url,
        method: 'DELETE',
      }),
      invalidatesTags: ['GenericItem'],
    }),
  }),
})

export const {
  useGetItemQuery,
  useGetItemsQuery,
  useGenericMutation,
  useUpdateOrCreateItemMutation,
  useDeleteItemMutation,
} = api

export const apiList = (res) => ({
  ...res,
  orData: res.data,
  data: res.data?.results || [],
  count: res.data?.count || 0,
})

export const objToQueryString = (obj) =>
  R.pipe(
    R.defaultTo({}),
    R.keys,
    R.map((a) => `${a}=${obj[a]}`),
    R.join('&'),
  )(obj)

export const apiQueryString = (qs) => {
  const more = Object.keys(qs?.qsAdditions || {})
    .filter(R.compose(R.not, R.isEmpty, R.flip(R.prop)(qs.qsAdditions)))
    .map((k) => `${k}=${qs.qsAdditions[k]}`)
  return objToQueryString(qs.base) + (more.length ? '&' + more.join('&') : '')
}

const requestOptions = () => {
  const accessToken = store.getState()?.auth?.login?.token

  return {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  }
}

const downloadRequest = async (requestPath) => {
  const url = `${config.apiBasePath}${requestPath}`
  const options = requestOptions()
  return await fetch(url, options)
}

const triggerDownload = async (result, fileName) => {
  const blob = await result.blob()
  const href = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', `${fileName}`) //or any other extension
  document.body.appendChild(link)
  link.click()
  toast.success(i18next.t('common:ui.DownloadStarted'))
  document.body.removeChild(link)
}

export const downloadFile = async (requestPath, fileName) => {
  try {
    let result = await downloadRequest(requestPath)

    const refreshToken = Storage.get('refreshToken', false)
    if (!result.ok && result.status !== 401) {
      throw new Error(`Error downloading file: ${fileName}!`)
    }

    if (!result.ok && result.status === 401 && refreshToken) {
      logger.debug('API 401 detected', requestOptions)
      if (!refreshLocked) {
        logger.debug('API no one is refreshing -> acquiring lock and actually refreshing now', requestOptions)
        refreshLocked = true // acquire lock
        let resolveRefreshTokenPromise
        refreshTokenPromise = new Promise((resolve) => {
          resolveRefreshTokenPromise = resolve
        })

        const refreshResponse = await fetch(`${config.apiBasePath}/auth/token/refresh`, {
          method: 'POST',
          body: JSON.stringify({ refresh: refreshToken }),
          headers: { 'Content-Type': 'application/json' },
        })

        if (refreshResponse.ok) {
          const credentials = await refreshResponse.json()
          logger.debug('API token refreshed', requestOptions)
          store.dispatch(setCredentials(credentials))
          logger.debug('API credentials set', requestOptions)
          logger.debug('API releasing refresh lock', requestOptions)
          resolveRefreshTokenPromise()
          refreshLocked = false
        } else {
          resolveRefreshTokenPromise()
          refreshLocked = false
          store.dispatch(logout())
          location.assign(config.urls.login)
          return result
        }
      }

      logger.debug('API would like to run again request', requestOptions)
      if (refreshLocked) {
        logger.debug('API wait, someone is refreshing token', requestOptions)
        await refreshTokenPromise
        logger.debug('API wait for other refreshing: DONE', requestOptions)
      }

      logger.debug('API actually running again request', requestOptions)
      // retry the initial query
      result = await downloadRequest(requestPath)
      logger.debug('API request retry: DONE', requestOptions)
    }

    await triggerDownload(result, fileName)
  } catch (err) {
    logger.error(err)
    toast.error(i18next.t('common:ui.DownloadError'))
  }
}
