import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'
import { Editor as DraftEditor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Controller } from 'react-hook-form'

import { Help } from '@Common/Components/Forms'
import { Label } from '@Common/Components/Styles'
import { FieldType } from '@Common/Types/ReactHookFormField'

const toolbar = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
  inline: { options: ['bold', 'italic', 'underline'] },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  },
  list: { options: ['unordered', 'ordered', 'indent', 'outdent'] },
  textAlign: { options: ['left', 'center', 'right', 'justify'] },
  history: { options: ['undo', 'redo'] },
}

const Editor = ({ onChange, value, toolbar, label, required, error, helperText, ...other }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : ''
      const blocksFromHtml = htmlToDraft(defaultValue)
      const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap)
      const newEditorState = EditorState.createWithContent(contentState)
      setEditorState(newEditorState)
    }
  }, [value])

  const onEditorStateChange = (editorState) => {
    setUpdated(true)
    setEditorState(editorState)

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  return (
    <>
      {!isNil(label) && (
        <Label>
          {label}
          {required && ' *'}
        </Label>
      )}
      <div className="editor">
        <DraftEditor
          spellCheck
          toolbar={toolbar}
          editorState={editorState}
          wrapperClassName="wysiwyg-wrapper"
          editorClassName="wysiwyg-editor"
          onEditorStateChange={onEditorStateChange}
          {...other}
        />
        {helperText && <Help error={!!error}>{helperText}</Help>}
      </div>
    </>
  )
}

const EditorField = ({ name, control, rules, defaultValue, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Editor value={value} onChange={onChange} toolbar={toolbar} {...other} />
      )}
    />
  )
}

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  toolbar: PropTypes.object,
  ...FieldType,
}
EditorField.propTypes = FieldType

export default EditorField
